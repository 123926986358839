.main-container__project-card {
  display: flex;
  flex-direction: column;
  padding: 4vw;
  max-width: 700px;

  * {
    font-size: 0.9rem;
  }

  a {
    position: relative;
  }

  .portfolio-img {
    height: 100%;
    width: 100%;
  }

  .project-card__view-app {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: black;
    color: white;
    opacity: 0%;
  }

  .project-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 20px 0px 0px 0px;
    border-top: 1px solid white;
    padding: 5px 0px 5px 0px;
    color: white;
    text-shadow: 1px 2px rgb(0, 0, 0);
    transform: scaleY(1.2);
  }

  .project-link {
    color: white;
    text-decoration: underline;
    margin: 5px 0px 30px 0px;
    min-width: fit-content;
    max-width: fit-content;

    &:hover {
      color: rgb(67, 255, 29);
    }
  }
}
