.socials-container {
  display: flex;

  &.home,
  &.nav {
    &.location {
      font-size: 1.5rem;
      margin-right: 3px;
    }

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      margin-right: 15px;

      svg {
        margin: 0;
      }
    }
  }

  &.home {
    .icon {
      font-size: 2rem;
    }
  }

  &.nav {
    .icon {
      font-size: 1.7rem;
    }
  }
}
